/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "global/layout";
@import "global/colors";
@import "global/fonts";
@import "global/base-components";


* {
    font-family: 'Sora', sans-serif;
    color: $brand-dark;
}

html {
    background-color: $brand-grey;
}
