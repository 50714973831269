/* BREAKPOINTS */
$breakpoint-xxs: 380px;
$breakpoint-xs: 459px;
$breakpoint-sm: 599px;
$breakpoint-md: 959px;
$breakpoint-lg: 1279px;
$breakpoint-xl: 1439px;
$breakpoint-xxl: 1599px;
$breakpoint-xxxl: 1799px;


/* ----------------------------------------------------------------------------------------------------- */
/*  @ Compact layout
/* ----------------------------------------------------------------------------------------------------- */

compact-layout {
    & fuse-vertical-navigation.fuse-vertical-navigation-appearance-compact {
        @apply bg-primary-200 #{!important};

        & .fuse-vertical-navigation-aside-wrapper {
            @apply bg-primary-50 #{!important};
        }
    }


    & fuse-vertical-navigation-aside-item > .fuse-vertical-navigation-item-wrapper {
        .fuse-vertical-navigation-item:not(.fuse-vertical-navigation-item-disabled).fuse-vertical-navigation-item-active {
            @apply bg-primary-300 #{!important};
        }
    }

    & fuse-vertical-navigation-basic-item > .fuse-vertical-navigation-item-wrapper {
        .fuse-vertical-navigation-item:not(.fuse-vertical-navigation-item-disabled).fuse-vertical-navigation-item-active {
            @apply bg-primary-300 #{!important};
        }
    }

    & .compact-header {
        @apply bg-primary-50 #{!important};
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Page layout
/* ----------------------------------------------------------------------------------------------------- */

.page-container {
    @apply flex flex-col h-full w-full min-w-0 bg-transparent;
}

.page-title {
    @apply font-semibold text-lg md:text-xl;
}

.page-description {
    @apply font-light opacity-80 text-sm md:text-md;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Custom component layout
/* ----------------------------------------------------------------------------------------------------- */

.custom-component-layout {
    @apply flex h-full w-full relative bg-transparent;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Mobile layout
/* ----------------------------------------------------------------------------------------------------- */

@media only screen and (hover: none) and (pointer: coarse) {
    input,
    select,
    textarea {
        font-size: 14px !important;
    }

    input:focus,
    select:focus,
    textarea:focus {
        font-size: 14px !important;
    }
}


