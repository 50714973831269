// PLATFORM COLORS

// Main
$black: #000000;
$white: #FFFFFF;
$brand-dark: #1A1817;
$brand-grey: #EDECEB;
$brand-cyan: #18B2A1;
$brand-light-cyan: #33BDAF;
$brand-pink: #F0B4C8;
